import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Video = ({ videoClass, videoSrcURL, videoCaption, ...props }) => (
  <div className={videoClass}>
    <iframe
      src={videoSrcURL}
      title={videoCaption}
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      frameBorder="0"
      webkitallowfullscreen="true"
      mozallowfullscreen="true"
      allowFullScreen
    />
  </div>
)

export default Video