import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Video from "../components/video"
import rehypeReact from "rehype-react"
import Storycard from "../components/story-card"
import Footer from "../components/footer"

export default ({ data }) => {
  const post = data.post

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: { "storycard": Storycard },
  }).Compiler

  const peopleReturns = data.people
  const allPeople = peopleReturns.edges
  const peopleCount = allPeople.length

  const stackIntroReturns = data.stackIntro
  const allStackintro = stackIntroReturns.edges
  const stackIntroCount = allStackintro.length

  return (
    <Layout navColor="standardNav">

        <div className="head-title">
          <h2>{post.frontmatter.title}</h2>
        </div>

        <div className="head-story-meta">
          <h5 className="timestamp">{post.frontmatter.date}</h5>
        </div>

        {
          (post.frontmatter.videoSrcURL) ?
            <Video
              videoClass="video head-video"
              videoSrcURL={post.frontmatter.videoSrcURL}
              videoTitle={post.frontmatter.videoCaption}
            />
          :

            <div className="head-image">
              <Img fluid={post.frontmatter.image.childImageSharp.fluid} />
              <div className="head-story-caption">
                <h5>{post.frontmatter.caption}</h5>
              </div>
            </div>
        }

        <div className="head-body">
          <div>{renderAst(post.htmlAst)}</div>
        </div>

        {
          (peopleCount > 0) ?
            <div className="head-story-people">
              <h4 className="label">
                Related people
              </h4>
              {data.people.edges.map(({node}) => (
                <Link to={node.fields.slug}>
                  <div className="ripple-link person-link">
                    <div className="ripple-image">
                      <Img fluid={node.frontmatter.image.childImageSharp.fluid} />
                    </div>
                    <div className="ripple-text person-text">
                      <h4 className="ripple-card-title">
                        {node.frontmatter.title}
                      </h4>
                      <h5 className="ripple-card-timestamp">
                        {node.frontmatter.blurb}
                      </h5>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          :
            null
        }

      <div className="story-story-card-attachment">
        
        {post.frontmatter.stack.map((stack) => (
          <div className="stack">
            {
              (stackIntroCount > 0) ?
                <div>
                  {data.stackIntro.edges.map(({ node }) => (
                    (stack == node.frontmatter.stack) ?
                      <div className="feed-label">
                        <h3>
                          {stack}
                        </h3>
                        <p className="stack-blurb">{node.frontmatter.blurb} <Link to={node.fields.slug} className="link-prompt">Read more →</Link></p>
                      </div>
                    :
                      null
                  ))}
                </div>
              :
                <div className="feed-label">
                  <h4>
                    {stack}
                  </h4>
                </div>
              }
            {data.stack.edges.map(({ node }) => (
              <div>
                {node.frontmatter.stack.map((stackstack) => (
                  (stack == stackstack) ?
                    <div className="story-card">
                      <Link to={node.fields.slug}>
                        <div className="story-card-text">
                          <h3>
                            {node.frontmatter.title}
                          </h3>
                          <h5 className="timestamp">
                            {node.frontmatter.date}
                          </h5>
                          <div>
                            {node.frontmatter.blurb}
                          </div>
                        </div>
                      </Link>
                    </div>
                  :
                    null
                ))}
              </div>
            ))}
          </div>
        ))}
      </div>      

      <div className="story-story-card-attachment sections-section">
        <div className="feed-label">
          <h4>
            Sections
          </h4>
        </div>
        {post.frontmatter.epic.map((epic) => (
          <div className="section-card">
            {data.relatedStories.edges.map(({ node }) => (
              (node.frontmatter.epic == epic) ?
                <Link to={node.fields.slug}>
                  <div className="story-card">
                    <div className="story-card-text">
                      <h3>
                        {node.frontmatter.title}
                      </h3>
                      <h5 className="timestamp">
                        {node.frontmatter.date}
                      </h5>
                      <div>{renderAst(node.htmlAst)}</div>
                      <h5 className="link-prompt"><strong>Go deeper →</strong></h5>
                    </div>
                  </div>
                </Link>
              :
                null
            ))}
          </div>
        ))}
      </div>

      <Footer
        className="story-footer"
        location="story"
      />
      
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!, $stack: [String!], $people: [String!]) {

    post: markdownRemark(
      fields: { slug: { eq: $slug }}
    ) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
        }
        caption
        videoSrcURL
        videoCaption
        epic
        stack
        attachment
        people
        date(formatString: "MMMM Do")
      }
      fields {
        slug
      }
      htmlAst
    }

    people: allMarkdownRemark(
      filter: {
        frontmatter: {
          title: {
            in: $people
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
          }
          fields {
            slug
          }
        }
      }
    }

    stackIntro: allMarkdownRemark(
      filter: {
        frontmatter: {
          stack: {
            in: $stack
          }
          type : {
            eq: "stack"
          }
        }
        fields : {
          slug: {
            ne: $slug 
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            stack
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
        }
      }
    }

    stack: allMarkdownRemark(
      filter: {
        frontmatter: {
          stack: {
            in: $stack
          }
          type: {
            ne: "stack"
          }
        }
        fields : {
          slug: {
            ne: $slug 
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
      limit: 3
    ) {
      edges {
        node {
          frontmatter {
            title
            blurb
            stack
            date(formatString: "MMMM Do")
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }

    relatedStories: allMarkdownRemark(
      filter: {
        frontmatter: {
          type: {
            eq: "home"
          }
        }
        fields: { 
          slug: { 
            ne: $slug 
          }
        }
      }
    ) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
            blurb
            type
            epic
            people
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst   
        }
      }
    }

    explainerStories: allMarkdownRemark(
      filter: {
        fields: { 
          slug: { 
            ne: $slug 
          }
        }
      }
      sort: { fields: [frontmatter___date], order: [DESC] }
    ) {
      edges {
        node {
          frontmatter {
            title
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            caption
            blurb
            type
            epic
            stack
            people
            date(formatString: "MMMM Do")
          }
          fields {
            slug
          }
          htmlAst   
        }
      }
    }
  }
`