import React from "react"
import { Link } from "gatsby"
import Video from "../components/video"
import Img from "gatsby-image"

export default props =>

  <div className="story-promo">        
    <div className="story-card">
      <Link to={props.slug}>
        <div className="story-card-text">
          <h3 className="title">
            {props.title}
          </h3>
          <h5 className="timestamp">
            {props.date}
          </h5>
        </div>
        {
          (props.videoURL) ?
            <Video
              videoClass="video head-video"
              videoSrcURL={props.videoSrcURL}
              videoTitle={props.videoCaption}
            />
          :
            <div className="story-card-image">
              <Img fluid={props.image} />
            </div>
        }
        <div className="story-card-text">
          <p>
            {props.blurb}
          </p>
        </div>
      </Link>
      <div className="pills">
        {(props.people).map((node, index, nodes) => (
          <div className="pill">
            <Link to={`/${node.split(" ").join("-").toLowerCase()}/`}>
              {node}
            </Link>
          </div>
        ))}
        {(props.epic).map((node, index, nodes) => (
          <div className="pill">
            <Link to={`/${node.split(" ").join("-").toLowerCase()}/`}>
              {node}
            </Link>
          </div>
        ))}
      </div>
    </div>
  </div>